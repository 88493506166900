.app {
    background: linear-gradient(#1D1C20 0%, #121212 50%) no-repeat;
}
.LogInScreen {
    height: 100vh;
    width: 100vw;
    display: flex;
    background: aliceblue;
    align-items: center;
    justify-content: center;
}

.LogInScreen > a {
    color: #000;
    font-size: 40px;
}

.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.flex-grow {
    flex-grow: 1;
}

.content {
    padding: 0 32px;
}

.top-bar, .bottom-bar, .top-bar-spacer {
    min-height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
}

.top-bar {
    border-bottom: 1px solid #282828;
}

.top-bar-spacer {
    border-bottom: 1px solid transparent;
}

.bottom-bar {
    min-height: 40px;
    background: #57B560;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0 0 0 16px;
}

.device-selector {
    padding: 0 32px;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
}

.device-selector li {
    margin-right: 16px;
    transition: opacity cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
    cursor: pointer;
}

.device-selector.has-selected li:not(:last-of-type) {
    opacity: 0;
}

.device-selector.has-selected:hover li {
    opacity: 1;
}

.play-button {
    cursor: pointer;
    background: transparent;
    border: none;
}

.collection-container {
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
}

.collection-item {
    padding: 16px;
    max-width: 100%;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    transition: transform cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
}

.collection-item.playing img {
    border-radius: 1000px;
    animation-duration: 3s;
    animation-name: spin;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes spin {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

@media (min-width: 450px) {
    .collection-item {
        max-width: 50%;
    }
}

@media (min-width: 800px) {
    .collection-item {
        max-width: 33%;
    }
}

@media (min-width: 1000px) {
    .collection-item {
        max-width: 25%;
    }
}

@media (min-width: 1200px) {
    .collection-item {
        max-width: 16.667%;
    }
}

.collection-item:hover {
    transform: scale(1.06);
}

.album-metadata {
    margin-top: 8px;
    color: gray;
}
.album-metadata span {
    color: white;
}

.collection-item img {
    width: 100%;
}