body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

.selected {
    font-weight: bold;
}


body {
    height: 100vh;
    width: 100vw;
    background-color: #121212;
    color: white;
    font-family: CircularBook;
    letter-spacing: .015em;
}

@font-face { 
    font-family: CircularBold; 
    src: url(/fonts/lineto-circular-pro-bold.woff); 
} 

@font-face { 
    font-family: CircularMediun; 
    src: url(/fonts/lineto-circular-pro-mediun.woff); 
} 

@font-face { 
    font-family: CircularBook; 
    src: url(/fonts/lineto-circular-pro-book.woff); 
} 

h1 {
    letter-spacing: -1px;
    font-size: 3em;
}